@import "index.scss";

.sidenavContainer {
  width: 270px;
  height: 100vh;
  background-color: $primary-background;
  color: white;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  .sidenavItemContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .logoutContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .sidenavItemWrapper {
    display: flex;
    font-size: 24px;
    align-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: #1a5474;
    }
  }
}

.mobileTabsContainer {
  display: flex;
  place-items: center;

  min-height: 50px;

  bottom: -10px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 3;

  color: #fff;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: $primary-background;

  .mobileTabsWrapper {
    color: inherit;
    font-size: 16px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
}

.drawerItemContainer {
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: $primary-background;

  .drawerItemWrapper {
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 1rem;
    gap: 0.5rem;
  }

  .logoutContainer {
    padding: 0.5rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 2rem;
  }
}
.version {
  padding: 0 1rem;
  opacity: 0.3;
}
