.menu-button-link {
  text-decoration: none;
  color: black;
}
.chatIconContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  .chatOpenButton {
    // width: 200px;
    height: 45px;
    button {
      width: 140px;
      height: 45px;
      border-radius: 2em;
      display: flex;
    }
    @media screen and (max-width: 600px) {
      width: 85px;
      height: 80px;
    }
  }
  .chat-button-close {
    @media screen and (max-width: 600px) {
      bottom: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    right: auto;
    left: 1em;
  }
}

.chatContainer {
  position: relative;
}

.chatPopup {
  position: absolute;
  background-color: #ffff;
  bottom: 100%;
  right: 0;
  z-index: 2;
  .chatContainerHeader {
    flex: 1 1 auto;
    flex-flow: column;
    max-height: 80vh;
    max-width: 100%;
    max-height: 550px;
    width: 276px;
    overflow: hidden auto;
    bottom: 120px;
    right: 20px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px 0px;
    background: rgb(255, 255, 255);
    display: flex;
    position: fixed !important;

    .failedToLoadMessage {
      padding: 1em;
      background: #f7ebea;
      font-size: 16px;
      font-weight: bold;
    }
    @media screen and (max-width: 600px) {
      bottom: 110px;
      right: auto;
    }
  }

  .chatHeader {
    min-height: 30px;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-flex: 1;
    flex-shrink: 1;
    overflow: hidden;
    // padding-bottom: 0.5rem;
    border-bottom: solid 1px #022b39;
    .imageChatHeader {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: auto;
      display: block;
      max-width: 150px;
      max-height: 36px;
      // width: auto;
      // height: auto;
    }
    .textChatHeaderContainer {
      display: flex;
      margin-left: 0.5rem;
      flex: 0 1 auto;
      -webkit-box-pack: center;
      justify-content: center;
      .textChatHeader {
        margin-top: 0.3rem;
        margin-bottom: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgb(34, 34, 34);
      }
    }
    mat-icon {
      margin: 0.3em;
      cursor: pointer;
    }
  }

  .closeChat {
    background: none;
    border: none;
    color: #333;
    font-size: 16px;
    cursor: pointer;
  }
}
.chatContainerInside {
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden auto;
  padding-left: 3.75%;
  padding-right: 3.75%;
  display: flex;
  position: relative;
  .welcomeMessageContainer {
    overflow: hidden;
    margin: 1rem auto;
    flex: 1 1 100%;
    color: rgb(34, 34, 34);
    display: flex;
    position: relative;
    min-height: 50px;

    .welcomeMessage {
      display: flex;
      flex-flow: column nowrap;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex-shrink: 1;
      overflow-x: hidden;
    }
  }
  .dateMessageContainer {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    min-height: 50px;
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    .dateMessage {
      display: flex;
      flex-flow: column nowrap;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex-shrink: 1;
      overflow-x: hidden;
      .dateMessageSeperator {
        display: flex;
        flex-flow: row nowrap;
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-shrink: 1;
        .seperator {
          flex: 1 1 1px;
          margin: auto;
          border-color: rgb(198, 202, 215);
        }
        .date {
          align-self: center;
          flex: 0 1 auto;
          margin-left: 12px;
          margin-right: 12px;
          font-size: 10px;
          letter-spacing: 2px;
          color: rgb(34, 34, 34);
        }
      }
    }
  }
  .chatMessagesContainer {
    z-index: -1;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    gap: 0.5rem;

    // <!-- CSS FOR INBOUND MESSAGES-->
    .messageContentContainerReceived {
      display: flex;
      flex-flow: row nowrap;
      -webkit-box-flex: 1;

      .messageAvatarContainer {
        display: flex;
        flex-flow: row nowrap;
        -webkit-box-flex: 0;
        flex-grow: 0;
        flex-shrink: 1;
        .messageAvatar {
          display: flex;
          width: 24px;
          height: 24px;
          -webkit-box-pack: center;
          justify-content: center;
          margin-right: 12px;
          border-radius: 18px;
          overflow: hidden;
          color: rgb(0, 0, 0);
          background: rgb(236, 237, 241);
          .avatarIcon {
            display: flex;
            align-items: center;
            .avatarImg {
              width: 24px;
              height: 24px;
              flex: 0 0 auto;
              margin: auto;
            }
          }
        }
      }
      .messageBubbleContainer {
        display: flex;
        flex-direction: column;
        max-width: 440px;
        min-width: 100px;
        overflow-x: hidden;
        margin-left: 0px;
        margin-right: 44px;
        .messageBubble {
          color: rgb(34, 34, 34);
          background: rgb(236, 237, 241);
          border-radius: 4px;
          margin-left: 0px;
          position: relative;
          overflow-x: hidden;
          display: flex;
          flex-flow: column nowrap;
          -webkit-box-flex: 1;

          .messageBubbleHeader {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 5px;
            -webkit-box-pack: justify;
            justify-content: space-between;
            color: rgb(34, 34, 34);
            display: flex;
            flex-flow: row nowrap;
            -webkit-box-flex: 1;

            .messageBubbleUsername {
              font-size: 12px;
              margin-top: 0px;
              margin-bottom: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 0 1 auto;
              margin-right: 8px;
              font-weight: bold;
            }
            .messageBubbleTime {
              font-size: 12px;
              margin-top: 0px;
              margin-bottom: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 0 0 auto;
            }
          }
          .messageBubbleBody {
            align-self: start;
            padding-left: 12px;
            padding-right: 12px;
            margin-top: 3px;
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 1.54;
            overflow-wrap: break-word;
            padding-bottom: 8px;
          }
        }
      }
    } // <!-- CSS FOR INBOUND MESSAGES END-->

    // <!-- CSS FOR OUTBOUND MESSAGES-->

    .messageContentContainerSent {
      display: flex;
      flex-flow: row nowrap;
      -webkit-box-flex: 1;

      .messageAvatarContainer {
        display: flex;
        flex-flow: row nowrap;
        -webkit-box-flex: 0;

        .messageAvatar {
          display: none;
          width: 24px;
          height: 24px;
          -webkit-box-pack: center;
          justify-content: center;
          margin-right: 12px;
          border-radius: 18px;
          overflow: hidden;
          color: rgb(0, 0, 0);
          background: rgb(236, 237, 241);
          .avatarIcon {
            display: flex;
            .avatarImg {
              width: 24px;
              height: 24px;
              flex: 0 0 auto;
              margin: auto;
            }
          }
        }
      }
      .messageBubbleContainer {
        display: flex;
        flex-direction: column;
        max-width: 440px;
        min-width: 100px;
        overflow-x: hidden;
        margin-left: auto;
        margin-right: 0px;
        .messageBubble {
          color: rgb(255, 255, 255);
          background: linear-gradient(
            120.73deg,
            #2d77a7 0%,
            #509dc5 100%
          ) !important;

          border-radius: 4px;
          margin-left: 32px;
          position: relative;
          overflow-x: hidden;
          display: flex;
          flex-flow: column nowrap;
          -webkit-box-flex: 1;

          .messageBubbleHeader {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 5px;
            -webkit-box-pack: justify;
            justify-content: space-between;
            color: rgb(255, 255, 255);
            display: flex;
            flex-flow: row nowrap;
            -webkit-box-flex: 1;

            .messageBubbleUsername {
              font-size: 12px;
              margin-top: 0px;
              margin-bottom: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 0 1 auto;
              margin-right: 8px;
              font-weight: bold;
            }
            .messageBubbleTime {
              font-size: 12px;
              margin-top: 0px;
              margin-bottom: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 0 0 auto;
            }
          }
          .messageBubbleBody {
            padding-left: 12px;
            align-self: start;
            padding-right: 12px;
            margin-top: 3px;
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 1.54;
            padding-bottom: 8px;
            overflow-wrap: anywhere;
          }
        }
      }
    }
    // <!-- CSS FOR OUTBOUND MESSAGES END-->
  }
}
.chatLoader {
  height: 100%;
  margin-top: 50%;
  margin-bottom: 50%;
  place-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chatClosedContainer {
  padding: 0 1rem;
  border: 1px solid;
  background: #ededed;
  display: flex;
  flex-direction: column;
}

.chatButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  position: relative;
  width: 104px;
  height: 40px;
  background: linear-gradient(120.73deg, #2d77a7 0%, #509dc5 100%) !important;

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 33px;
  position: relative;
  z-index: 1;
  .chatButtonHeader {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .helpIcon {
    width: 20px;
    height: 20px;
  }
}
.chatButtonClose {
  border-radius: 2rem !important;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 40px;
  height: 40px;

  background: linear-gradient(120.73deg, #2d77a7 0%, #509dc5 100%) !important;

  border-radius: 33px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.chatInputContainer {
  padding-left: 3%;
  padding-right: 3.75%;
  color: rgb(34, 34, 34);
  background: transparent;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  .chatInputDefault {
    display: flex;
    flex-flow: column nowrap;
    -webkit-box-flex: 1;

    overflow-x: hidden;
    .chatInput {
      padding-top: 8px;
      padding-bottom: 12px;
      align-items: flex-end;
      display: flex;
      flex-flow: row nowrap;
      -webkit-box-flex: 1;

      .chatInputArea {
        border-radius: 18px;
        display: block;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        background: rgb(236, 237, 241);
        color: rgb(34, 34, 34);
        textarea {
          height: 36px;
          min-height: 36px;
          max-height: 82px;
          border: 0px;
          display: block;
          font-size: 14px;
          font-family: inherit;
          width: 100%;
          resize: none;
          outline: none;
          box-sizing: border-box;
          padding: 8px 12px;
          overflow: hidden;
          background: rgb(236, 237, 241);
          color: rgb(34, 34, 34);
        }
      }
      .sendMessage {
        display: flex;
        flex: 0 1 auto;
        margin-left: 8px;
        .sendMessageButton {
          padding: 0px;
          border: none;
          outline: none;
          border-radius: 100px;
          align-self: center;
          width: 36px;
          height: 36px;
          color: rgb(255, 255, 255);
          background: linear-gradient(
            120.73deg,
            #2d77a7 0%,
            #509dc5 100%
          ) !important;

          .sendIcon {
            display: flex;
            width: 90%;
            height: 55%;
            flex: 0 0 auto;
            margin: auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}
