.contentContainer {
  gap: 1rem;
  display: flex;
  flex-direction: column;

  .contentTitle {
    border-radius: 0.5rem;
    padding: 0.5rem;
    background: linear-gradient(120.73deg, #2d77a7 0%, #022b39 100%);
    text-align: center;
    color: white;
  }
}
