@tailwind base;
@tailwind utilities;

$primary-background: #022b39;

@layer base {
  h1,
  h2,
  h3,
  h4,
  body {
    color: inherit;
    font-style: normal;
    letter-spacing: 0.1px;
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 167%;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 200%;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 167%;
  }

  h4,
  body {
    font-size: 14px;
    font-weight: 400;
    line-height: 180%;
  }
}

.details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // padding-bottom: 0.5rem;
}

.details-wrapper-title {
  text-align: center;
  background: linear-gradient(120.73deg, #2d77a7 0%, #022b39 100%);
  color: #ffff;
  &.about-me,
  &.my-job {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}
// @media (min-width: 768px) {
//   .details-wrapper-title {
//     &.about-me,
//     &.my-job {
//       text-align: center;
//       background: linear-gradient(120.73deg, #2d77a7 0%, #509dc5 100%);
//       color: #ffff;
//     }
//   }
// }

.details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 0 0 0.5rem 0.5rem;

  &.about-me {
    border: 2px solid #1a5474;
  }
}

@media (min-width: 768px) {
  .details-wrapper {
    &.about-me,
    &.my-job {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      justify-items: center;
      border-radius: 0 0 0.5rem 0.5rem;
    }
    &.about-me {
      border: 2px solid #1a5474;
    }
  }
}

.details-content {
  display: flex;
  align-items: center;
  flex: 1;

  gap: 0.5rem;
  flex-wrap: wrap;

  .details-question {
    color: #a9a9a9;
  }
  @media screen and (min-width: 768px) {
    &.timeband {
      flex-direction: column;
      text-align: center;
      h3 {
        flex: 1;
        align-content: flex-end;
      }
    }
  }
}

@media (min-width: 768px) {
  .details-content {
    &.about-me,
    &.my-job {
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  }
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;

  background: linear-gradient(120.73deg, #022b39 0%, #1a5474 100%);
  color: white;

  .card-icon {
    font-size: 50px;
  }
  .card-description {
    text-align: center;
  }
}

.button-primary {
  background: linear-gradient(120.73deg, #2d77a7 0%, #509dc5 100%) !important;
}
.button-secondary {
  background: #022b39 !important;
}

.active {
  background-color: #1a5474;
}

.error-text {
  color: crimson;
}

.swal2-title {
  font-size: 20px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 11000;
}
.fc-event {
  cursor: pointer;
}

.fc-day-today {
  background: inherit !important;
  border: 2px solid red !important;
}
.fc-toolbar h2 {
  display: inline;
}
.fc .fc-button-group {
  gap: 0.5rem;
}
.fc .fc-toolbar.fc-header-toolbar {
  align-items: flex-end;
}
.fc-toolbar-chunk {
  display: flex;
  flex-direction: column;
}
.fc .fc-toolbar-title {
  margin-left: 0.7rem;
}
.fc .fc-button:last-child {
  margin-left: 1rem !important;
}
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  text-wrap: pretty;
  // word-break: break-word;
}

.fc .fc-daygrid-day-frame {
  min-height: 100px;
}

.css-nbntra {
  background: #2d77a7 !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a5474;
  border-radius: 1em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a5474;
}
